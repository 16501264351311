import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import {
  CardsModule, CheckboxModule, ChipsModule,
  DropdownModule, DynamicFormModule, FormArrayComponent,
  FormButtonsModule, HeaderModule, HoveredDirective, TextboxModule,
} from '@lc/ui';

// Checkout Components
import { MatTooltip } from '@angular/material/tooltip';
import { SurveyFormComponent } from './survey-form.component';

// Internal components
import { SurveyInputFieldComponent } from './survey-input-field';

const angularModules = [
  CommonModule, MatListModule, MatFormFieldModule,
  MatInputModule, MatButtonModule, MatIconModule, MatTooltip,
];
const lcModules = [
  CheckboxModule, TextboxModule, DropdownModule,
  CardsModule, HeaderModule, FormButtonsModule,
  FormArrayComponent, DynamicFormModule, HoveredDirective,
  ChipsModule,
];
const externalComponents = [SurveyFormComponent];
const lcComponents = [SurveyInputFieldComponent];

@NgModule({
  declarations: [
    ...externalComponents,
    ...lcComponents,
  ],
  exports: [
    ...externalComponents,
  ],
  imports: [
    ...angularModules,
    ...lcModules,
  ],
})
export class SurveyFormModule { }
