<form [formGroup]="form">
  <lc-card [header]="header" [loading]="spinner">
    <ng-template #content>
      <lc-textbox formControlName="label" label="Label" />
      <lc-dropdown displayPath="display" formControlName="inputType" label="Input Type"
                   valuePath="value" [options]="form.inputOptions" />
      <lc-textbox formControlName="key" label="Key" />
      <lc-textbox formControlName="hint" label="Hint" />
      <lc-textbox formControlName="placeholder" label="Placeholder" />

      <lc-form-array *ngIf="form.hasOptions$ | async" label="Options" [form]="form.options">

        <ng-template #edit let-optionForm="form">
          <ng-container [formGroup]="optionForm">
            <lc-textbox formControlName="label" label="Label" />

            <lc-dropdown label="Value Type" placeholder="ex: String, Boolean, Number"
                         [formControl]="optionForm.valueType"
                         [options]="optionForm.valueTypes" />

            <ng-container [ngSwitch]="optionForm.valueType$ | async">
              <lc-checkbox *ngSwitchCase="'Boolean'" formControlName="value"
                           label="Value" />

              <lc-textbox *ngSwitchCase="'Number'" formControlName="value" label="Value"
                          placeholder="i.e. 10.00" [inputMask]="MASKS.decimalWithWholeNumber"
                          [maskDropSpecialCharacters]="true"
              />

              <lc-textbox *ngSwitchDefault formControlName="value" label="Value" />

            </ng-container>
            <!-- <pre>{{optionForm.value | json}}</pre> -->
          </ng-container>
        </ng-template>

        <ng-template #view let-optionForm="form">
          <lc-header *ngIf="optionForm?.value as option" [header]="option?.label"
                     [subheader]="option?.value" />
        </ng-template>

      </lc-form-array>

      <!-- <pre>{{form.options.value | json}}</pre>
      <pre>{{form.value | json}}</pre> -->
      <!-- <lc-textbox label="Order" formControlName="order"></lc-textbox> -->
      <!-- <lc-textbox label="Default Value" formControlName="defaultValue"></lc-textbox> -->
      <!-- <lc-textbox label="Validators" formControlName="validators"></lc-textbox> -->
      <!-- <lc-textbox label="Options" formControlName="options"></lc-textbox> -->
      <!-- <lc-textbox label="Group Key" formControlName="groupKey"></lc-textbox> -->
    </ng-template>

    <ng-template #actions>
      <lc-form-buttons (cancel)="onCancel()" (save)="onSave($event)" />
    </ng-template>
  </lc-card>
</form>
