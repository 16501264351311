<div class="fx-column">

  <div class="mat-elevation-z1">
    <table
      class="w-100"
      mat-table
      matSort
      matSortActive="name"
      matSortDirection="asc"
      multiTemplateDataRows
      [dataSource]="data"
    >

      <tr *matNoDataRow class="mat-row">
        <td class="mat-cell mat-hint" [attr.colspan]="columns.length">
          <span>No data matching the filter.</span>
        </td>
      </tr>

      <!-- expand column -->
      <ng-container matColumnDef="actions" stickyEnd>
        <th *matHeaderCellDef mat-header-cell>
          <button
            mat-icon-button
            matTooltipPosition="above"
            [disabled]="data.filteredData.length === 0"
            [matTooltip]="isAllExpanded ? 'Collapse rows' : 'Expand rows'"
            (click)="onToggleExpandAll(!isAllExpanded)"
          >
            <mat-icon>{{isAllExpanded ? 'expansions_up' : 'expansions_down'}}</mat-icon>
          </button>
        </th>
        <td *matCellDef="let vm" mat-cell (click)="$event.stopPropagation()">
          <button
            mat-icon-button
            matTooltipPosition="above"
            [disabled]="data.filteredData.length === 0"
            [matTooltip]="isAllExpanded ? 'Collapse rows' : 'Expand rows'"
            (click)="onToggleExpandAll(!isAllExpanded)"
          >
            <mat-icon>{{isAllExpanded ? 'expansions_up' : 'expansions_down'}}</mat-icon>
          </button>
        </td>
      </ng-container>

      <!-- expanded element -->
      <ng-container matColumnDef="responses">
        <td *matCellDef="let vm" mat-cell [attr.colspan]="columns?.length">
          <div class="survey-response-details" [@detailExpand]="vm == expandedElement ? 'expanded' : 'collapsed'">
            <div *ngIf="expandedElement === vm" [style.padding]="'15px'">

              <div class="mat-title">Survey Feedback</div>

              <ng-container *ngIf="(vm?.statistics$ | async) as statistics">
                <ng-container *ngIf="isEmptyObject(statistics.counts); else counts">
                  <div><em>No feedback yet...</em></div>
                </ng-container>
                <ng-template #counts>
                  <ng-container *ngFor="let stat of statistics.counts | keyvalue">
                    <div class="font-weight-bolder">
                      {{vm?.response?.form?.inputs?.[stat.key]?.label}}
                    </div>
                    <ng-container *ngIf="isNumber(stat.value); else optionResponses">
                      <div>Number of responses: {{ stat.value }}</div>
                    </ng-container>
                    <ng-template #optionResponses>
                      <ng-container *ngFor="let count of stat.value | keyvalue">
                        <div>{{count.key}}: {{count.value}}</div>
                      </ng-container>
                    </ng-template>
                  </ng-container>
                </ng-template>
              </ng-container>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="title">
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
        >
          Survey Title
        </th>
        <td *matCellDef="let vm" mat-cell>
          {{ vm.survey.title }}
        </td>
      </ng-container>

      <ng-container matColumnDef="key">
        <th
          *matHeaderCellDef
          mat-header-cell
          mat-sort-header
        >
          Survey Key
        </th>
        <td *matCellDef="let vm" mat-cell>
          {{ vm.survey.key }}
        </td>
      </ng-container>


      <ng-container matColumnDef="empty">
        <td *matCellDef="let vm" mat-cell></td>
      </ng-container>

      <tr *matHeaderRowDef="columns" mat-header-row></tr>
      <tr
        *matRowDef="let vm; columns: columns" class="survey-row"
        mat-row
        [class.expanded-survey-row]="expandedElement === vm"
        (click)="setExpandedRow(vm)"
      ></tr>
      <tr *matRowDef="let row; columns: ['responses']" class="response-row" mat-row></tr>

    </table>

    <mat-paginator color="accent" showFirstLastButtons [pageSizeOptions]="[100, 500, 1000, 2500]" />
  </div>
</div>