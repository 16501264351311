<lc-card *ngIf="form" [formGroup]="form" [header]="header" [loading]="spinner">
  <ng-template #content>
    <div class="form-section">
      <lc-checkbox class="mb-1" formControlName="active" label="Active" />

      <lc-textbox formControlName="title" label="Survey Title" [required]="true"
                  (blur)="setSurveyKey()"
      />

      <lc-textbox formControlName="key" hint="Used as an identifier in API calls" label="Survey Key"
                  [readonly]="!form.isNew"
      />

      <lc-textbox formControlName="description" label="Survey Description" />
    </div>

    <lc-chips formControlName="distinctOn" hint="What fields make this survey unique?" label="Distinct Fields"
              placeholder="ex: 'userId', 'orderId'" size="sm"
              [allowCustomInput]="true" />

    <lc-form-array hint="Add or remove scopes" label="Scoping" [form]="form.get('scopes')">
      <ng-template #view let-scopeForm="form">
        <div *ngIf="scopeForm?.value as scope">
          <div class="mat-body-2 text-bold line-height-0">
            {{scope.title }}
          </div>

          <div class="mat-caption line-height-0 fx-column">
            <ng-container *ngFor="let item of scope.filters | keyvalue; let last = last">
              <span>
                <b>{{item.key}}</b>
                <ng-container *ngIf="item.value as filter">
                  {{filter.operator}} {{filter.value}}
                </ng-container>
              </span>
            </ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template #edit let-scopeForm="form">
        <!-- {{(scopeForm.value || {unknown: true}) | json}} -->

        <form class="fx-column" [formGroup]="scopeForm">
          <lc-textbox formControlName="title" hint="The description of this scope"
                      label="Scope Title"
                      placeholder="ex: 'California Users'"
          />

          <!-- Filters -->
          <lc-form-array hint="Add or remove filters" label="Filters"
                         [form]="scopeForm.filtersArray">
            <ng-template #view let-filterForm="form">
              <lc-header *ngIf="filterForm?.value as filter" [header]="filter.key"
                         subheader="{{filter.operator}} {{filter.value}}" />
            </ng-template>

            <ng-template #edit let-filterForm="form">

              <div class="fx-column py-1" [formGroup]="filterForm">
                <lc-textbox formControlName="key" hint="They key of the field" label="Field Name"
                            placeholder="ex: marketingArea"
                />
                <lc-dropdown label="Field Type" placeholder="ex: String, Boolean, Number"
                             [formControl]="filterForm.valueType"
                             [options]="filterForm.valueTypes"
                />
                <lc-dropdown formControlName="operator" label="Operator"
                             placeholder="ex: 'equals', 'not-equals', etc."
                             [options]="filterForm.operators$ | async"
                />

                <ng-container [ngSwitch]="filterForm.valueType$ | async">
                  <!-- Boolean Values -->
                  <lc-checkbox *ngSwitchCase="'Boolean'" formControlName="value"
                               label="Value"
                  />

                  <!-- Number Values-->
                  <lc-textbox *ngSwitchCase="'Number'" formControlName="value" label="Value"
                              placeholder="i.e. 10.00" [inputMask]="MASKS.decimalWithWholeNumber"
                              [maskDropSpecialCharacters]="true"
                  />

                  <!-- String Values-->
                  <ng-container *ngSwitchDefault>
                    <ng-container [ngSwitch]="filterForm.isList$ | async">
                      <lc-chips *ngSwitchCase="true" formControlName="value" hint="They values this field contains"
                                label="value"
                                placeholder="ex: ['value1', 'value2']"
                                [allowCustomInput]="true"
                      />

                      <lc-textbox *ngSwitchDefault formControlName="value" hint="They value of the field"
                                  label="value"
                                  placeholder="ex: value1"
                      />
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-template>
          </lc-form-array>
        </form>
      </ng-template>
    </lc-form-array>


    <div class="form-section" [formGroup]="form.dynamicForm">
      <div class="mat-body-2">Survey Form</div>

      <mat-form-field appearance="outline" class="form-array w-100 small" floatLabel="always">
        <mat-label>Questions:</mat-label>

        <!-- Hidden matInput plugs into formControl -->
        <input class="d-none" matInput [errorStateMatcher]="matcher">

        <mat-list class="height-auto h-100 p-0 fx-column">

          <!-- List of all uploaded files-->
          <ng-container *ngFor="let kvp of form.dynamicForm.inputs.controls | keyvalue">
            <ng-container *ngIf="kvp.value as childForm">

              <mat-list-item #listItem="hovered" class="border-bottom py-1 font-size-inherit"
                             lcHovered>

                <div *ngIf="childForm.value as input" class="w-100 fx-row y-center fx-gap-sm" [style.min-height.px]="40">
                  <lc-header [header]="input.label" [subheader]="input.inputType" />


                  <div class="fx-1-1-0">
                    <mat-icon *ngIf="childForm?.dirty" color="primary"
                              matTooltip="Unsaved changes">published_with_changes
                    </mat-icon>
                  </div>

                  <!-- Delete Button: displays when hovered -->
                  <ng-container *ngIf="listItem.isHovered">
                    <button mat-icon-button>
                      <mat-icon class="material-icons-outlined" (click)="onEdit(childForm)">
                        edit
                      </mat-icon>
                    </button>

                    <button mat-icon-button>
                      <mat-icon class="material-icons-outlined" (click)="onDelete(childForm)">
                        delete
                      </mat-icon>
                    </button>
                  </ng-container>
                </div>
              </mat-list-item>
            </ng-container>
          </ng-container>

          <!-- Add Button -->
          <mat-list-item class="p-0 fx-row x-end">
            <button mat-icon-button (click)="onAdd()">
              <mat-icon>add</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>

        <mat-error *ngIf="form && form.invalid" class="error-message" [innerHTML]="form.errors" />
      </mat-form-field>
    </div>
  </ng-template>

  <ng-template #actions>
    <lc-form-buttons (cancel)="onCancel()" (save)="onSave()" />
  </ng-template>
</lc-card>
