<lc-card *ngIf="config" [loading]="spinner">
  <ng-template #header>
    <lc-header type="card" [header]="config.survey.title!" [subheader]="config.survey.description">
      <ng-template #suffix>
        <button *ngIf="canClose" mat-icon-button (click)="onCancel()">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>
    </lc-header>
  </ng-template>
  <ng-template #content>
    <lc-dynamic-form [model]="config.survey.form" (saved)="onSave($event)" />
  </ng-template>
</lc-card>